import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from 'ui';

import { useAppSelector } from '@/data/client-state/hooks/useStore';
import { dialogSlice } from '@/data/client-state/slices/ui/dialogSlice';
import { handleLogout } from '@/utils/authHandler';

export default function UnauthenticatedDialog() {
  const dispatch = useDispatch();
  const router = useRouter();
  const { displayUnauthenticatedDialog, messageUnauthenticatedDialog } = useAppSelector(
    (state) => state.dialog
  );

  function handleRedirectToLogin() {
    dispatch(dialogSlice.actions.hideUnauthenticatedDialog());
    handleLogout(router);
  }

  return (
    <Dialog
      openDialog={displayUnauthenticatedDialog}
      setOpenDialog={handleRedirectToLogin}
      contentText={messageUnauthenticatedDialog}
      buttons={[
        {
          label: 'OK',
          handleClick: handleRedirectToLogin,
        },
      ]}
    />
  );
}
