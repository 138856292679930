import { useQuery } from 'react-query';

import { getUserInformationFromPermyo } from '@/data/server-state/api-clients/permyoApiClient';

export const useUserInformationQuery = () => {
  const query = useQuery(
    ['user-information'],
    () => {
      return getUserInformationFromPermyo().then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  return query;
};
