import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { NextPage } from 'next';
import { ReactNode } from 'react';

import { HeaderLayout } from '@/components/common-components/layouts/HeaderLayout';
import { SideNavigationBarContainer } from '@/components/common-components/layouts/side-navigation-bar/SideNavigationBarContainer';
import UnauthenticatedDialog from '@/components/pages-components/login/UnauthenticatedDialog';

const ContentWrap = styled.div`
  margin-left: 193px;
  margin-top: 63px;
  width: calc(100vw - 63px);
`;

interface LayoutProps {
  children: NextPage | ReactNode;
}

export const LayoutWithNavigationAndHeader = ({ children }: LayoutProps) => {
  return (
    <>
      <Stack direction={'column'}>
        <HeaderLayout />
        <Stack direction={'row'}>
          <SideNavigationBarContainer show={true} />
          <ContentWrap>{children as ReactNode}</ContentWrap>
        </Stack>
      </Stack>
      <UnauthenticatedDialog />
    </>
  );
};
