import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button, CloseButton } from 'ui';
import BasePopup from 'ui/components/molecules/BasePopup';

import { useUserInformationQuery } from '@/data/server-state/hooks/useUserInformation';
import { handleLogout } from '@/utils/authHandler';

type User = {
  name: string;
};

interface HeaderProps {
  user?: User;
  onGoHome: () => void;
  onLogout: () => void;
}

const Wrapper = styled.div`
  border-bottom: 0.5px solid #a8a8a8;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0%;
  width: 100vw;
  z-index: 50;
  background-color: #fff;
`;

const Logo = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
  color: #fd184f;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

const Welcome = styled.span`
  color: #333;
  font-size: 14px;
  margin-right: 10px;
  text-transform: capitalize;
`;

const CustomButton = styled(Button)`
  font-size: 12px;
`;

const Header = ({ user, onGoHome, onLogout }: HeaderProps) => (
  <Wrapper>
    <div>
      <Logo onClick={onGoHome}>Memberyo</Logo>
    </div>
    <InfoContainer>
      <Welcome>{user?.name}</Welcome>
      <CustomButton size="small" onClick={onLogout}>
        Logout
      </CustomButton>
    </InfoContainer>
  </Wrapper>
);

export const HeaderLayout = (): JSX.Element => {
  const router = useRouter();
  const theme = useTheme();
  const useUserInformation = useUserInformationQuery();

  const [showConfirm, setShowConfirm] = useState(false);

  const handleGoHome = () => {
    router.push('/');
  };

  const handleLogoutConfirmation = () => {
    setShowConfirm(true);
  };

  return (
    <>
      <Header
        onGoHome={handleGoHome}
        onLogout={handleLogoutConfirmation}
        user={{
          name: useUserInformation.data?.name || '',
        }}
      />
      {showConfirm && (
        <BasePopup setPopup={setShowConfirm} onClose={() => setShowConfirm(false)} width={420}>
          <PopupWrapper data-cy="logout-popup">
            <TitleWrapper>
              <CloseButton className="popup_close" onClick={() => setShowConfirm(false)} />
            </TitleWrapper>
            <PopupContent
              data-cy="logout-title"
              css={css`
                ${theme.fonts.title6 as string}
              `}
            >
              로그아웃 하시겠습니까?
            </PopupContent>
            <PopupFooter>
              <Button size="medium" primary onClick={() => handleLogout(router)}>
                확인
              </Button>
              <Button size="medium" onClick={() => setShowConfirm(false)}>
                취소
              </Button>
            </PopupFooter>
          </PopupWrapper>
        </BasePopup>
      )}
    </>
  );
};

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 62px;
  position: fixed;
  left: 0;
  top: 0;
`;

const PopupContent = styled.div`
  padding: 40px;
`;

const PopupFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
