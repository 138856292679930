import axios from 'axios';

import { UserInformation } from '@/data/server-state/types/userInformation';
import { getAuthorizationHeader } from '@/utils/cookieHandler';

export const permyoApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PERMYO_API_URL,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: getAuthorizationHeader(),
  },
});

export const getUserInformationFromPermyo = () => {
  return permyoApiClient.get<UserInformation>('/api/admin/users/me');
};
