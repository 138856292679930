import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Head from 'next/head';
import { ReactNode } from 'react';
import { LoadingBackDrop, LoadingBasic } from 'ui';

import { LayoutWithNavigationAndHeader } from '@/components/common-components/layouts/LayoutWithNavigationAndHeader';

export default function Home() {
  return (
    <>
      <Head>
        {/* TODO: Update the meta tags */}
        <title>요기요 사용자 관리</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <LoadingBackDrop>
        <LoadingBasic />
      </LoadingBackDrop>
    </>
  );
}

Home.getLayout = function getLayout(page: ReactNode) {
  return <LayoutWithNavigationAndHeader>{page}</LayoutWithNavigationAndHeader>;
};
